import React from "react"
import useMinimalConfig from "../../hooks/use-minimal-config"
import { HeaderType } from "../../types"

type LayoutProps = {
  header: HeaderType
  [key: string]: any
}

const Header = ({ header, ...props }: LayoutProps) => {
  const { navigation: nav } = useMinimalConfig()

  return (
    <React.Fragment>
    </React.Fragment>
  )
}

export default Header
